import React, {useState, useEffect } from 'react'
import './Add_funds.css';
import { Modal,ModalHeader,ModalBody,Row } from 'reactstrap';
import { Link, parsePath,useParams } from "react-router-dom"
import Add_funds_Paginations from './Add_funds_Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";

export default function Add_funds() {

  const [funds, setFunds]= useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentPosts = funds.slice(firstPostIndex, lastPostIndex)

  const [modalrefund, setModalrefund] = useState(false);
  
  const [id, setId] = React.useState("");
  const [balance, setBalance] = React.useState("");
  const [work_id, setWork_id] = React.useState("");
  
  const [patLoading, setPatLoading] = useState(false);

  const params = useParams();

    useEffect(()=>{
      get_funds()
    },[])
    const get_funds= async()=>{
        let  result =await fetch(Connection + "get_funds",{
        })
        result= await result.json()
         setFunds(result)
         console.warn(result)
    }


   const open_add_fund_modal = (user_id,work_id) => {
      setModalrefund(true)

      setId(user_id)
      setWork_id(work_id)
   }


   const add_fund_done = async (event) => {
     event.preventDefault();

     if(!balance){
      alert('Please enter amount.');
      return false
     }
     else{
      setPatLoading(true);

      let result = await fetch(Connection + `add_fund/${id}`,{
       method:'post',
       body: JSON.stringify({balance,work_id}),
       headers:{
           "Content-Type":'application/json'
       }
      })
     
      result = await result.json()
      console.log("result123",result)

      if(result){
       setPatLoading(false);
       setModalrefund(false)
       get_funds()
      }
     }
   }
 
    return (
        <div className='Add_funds_main_table'>
           
            <div className='respons_Add_funds_card'>
               <div className='head_card'>
                   <h2>Add funds</h2>
               </div>
              <hr></hr>
 
              <div className='Add_funds_table'>
                <table className='Add_refunds_table_tag'>
                           <tr>
                               <th>#</th>
                               <th>User Id</th>
                               <th>User Name</th>
                               <th>Work Id</th>
                               <th>Action</th>
                           </tr>


                      {currentPosts.map((row) => (
                           <tr>
                               <td>{row.id}</td>
                               <td>{row.user_id}</td>
                               <td>{row.user_name}</td>
                               <td>{row.work_id}</td>
                               <td>
                                  <span className='transfer_btn'>
                                       <button onClick={() => open_add_fund_modal(row.user_id,row.work_id)}>Add Funds</button>
                                  </span>
                               </td>
                           </tr>
                      ))}
                </table>
             </div>
            </div>

            <Add_funds_Paginations totalPosts={funds.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>

            <Modal size='lg' isOpen={modalrefund} toggle={() => setModalrefund(!modalrefund)}>
               <ModalHeader toggle={() => setModalrefund(!modalrefund)}>
                  Add Funds
               </ModalHeader>

               <ModalBody>
                  <div className='Add_fund_modal'>
                      <h4>Are you sure, You want to add fund in this user account.</h4>

                      <div className="Amount_Main">
                        <div className='label_head'>
                           <div className='label_div'>
                             <label>Amount</label>
                           </div>
                           <input type="number" className="amount" id="subject" placeholder="Enter Amount" onChange={(e)=>setBalance(e.target.value)}/>
                        </div>
                      </div>

                      {patLoading ?
                        <button id='refund_loader'>
                          <PulseLoader
                               color={"white"}
                               loading={patLoading}
                              //  size={5}
                               aria-label="Loading Spinner"
                               data-testid="loader"
                          />
                        </button>
                      :
                       <button onClick={add_fund_done}>Done</button>
                      }
                  </div>
               </ModalBody>
            </Modal>

         </div>
    );
}