import React, {useState, useEffect } from 'react'
import './Pending_payout.css';
import { Modal,ModalHeader,ModalBody,Row } from 'reactstrap';
import { Link, parsePath,useParams } from "react-router-dom"
import Pending_payout_Paginations from './Pending_payout_Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";

export default function Pending_payout() {

  const [pending_payout, setPending_payout]= useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentPosts = pending_payout.slice(firstPostIndex, lastPostIndex)

  const [modaltransferred, setModalTransferred] = useState(false);
  
  const [id, setId] = React.useState("");

  const [patLoading, setPatLoading] = useState(false);


  const params = useParams();

    useEffect(()=>{
      get_Pending_payout()
    },[])
    const get_Pending_payout= async()=>{
        let  result =await fetch(Connection + "get_pending",{
           
        })
        result= await result.json()
         setPending_payout(result)
         console.warn(result)
    }


    const open_transaction_modal = (doctor_id) => {
       setModalTransferred(true)

       setId(doctor_id)
    }
 

    const transaction_done = async (event) => {
      event.preventDefault();

      setPatLoading(true);

      let result = await fetch(Connection + `transfer_payout/${id}`,{
        method:'post',
        headers:{
            "Content-Type":'application/json'
        }
      })
      
      result = await result.json()
      console.log("result123",result)

      if(result){
        setPatLoading(false);
        setModalTransferred(false)
        get_Pending_payout()
      }
    }
    
    return (
        <div className='Pending_payout_table'>
           
            <div className='respons_Pending_payout_card'>
               <div className='head_card'>
                   <h2>Pending Payout</h2>
               </div>
              <hr></hr>
 
              <div className='table_Pending_payout'>
                <table className='Pending_payout_tble_tag'>
                           <tr>
                               <th>Payout Id</th>
                               <th>Doctor Id</th>
                               <th>Doctor Name</th>
                               <th>Amount</th>
                               <th>Bank Name</th>
                               <th>Account Number</th>
                               <th>Date</th>
                               <th>Action</th>
                           </tr>


                      {currentPosts.map((row) => (
                           <tr>
                               <td>{row.id}</td>
                               <td>{row.doctor_id}</td>
                               <td>{row.doctor_name}</td>
                               <td>${row.amount}</td>
                               <td>{row.bank_name}</td>
                               <td>{row.acc_no}</td>
                               <td>{row.date}</td>
                               <td>
                                  <span className='transfer_btn'>
                                       <button onClick={() => open_transaction_modal(row.doctor_id)}>Transferred</button>
                                  </span>
                               </td>
                           </tr>
                      ))}
                </table>
             </div>
            </div>

            <Pending_payout_Paginations totalPosts={pending_payout.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>


            <Modal size='lg' isOpen={modaltransferred} toggle={() => setModalTransferred(!modaltransferred)}>
               <ModalHeader toggle={() => setModalTransferred(!modaltransferred)}>
                  Transaction
               </ModalHeader>

               <ModalBody>
                  <div className='pending_modal'>
                      <h4>Are you sure, You want to transferred.</h4>
                      {patLoading ?
                        <button id='delete_loader'>
                          <PulseLoader
                               color={"white"}
                               loading={patLoading}
                              //  size={5}
                               aria-label="Loading Spinner"
                               data-testid="loader"
                          />
                        </button>
                      :
                       <button onClick={transaction_done}>Done</button>
                      }
                  </div>
               </ModalBody>
            </Modal>

         </div>
    );
}