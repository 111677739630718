import React, {useState, useEffect, useMemo, useContext } from 'react'
import './Login.css';
// import logo from '../../logo.svg';
import Mas_ClinicaLogo_2 from '../../assets/Mas_ClinicaLogo_2.png';
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import {BrowserRouter, Routes, Route,useNavigate, Link} from "react-router-dom"
import PulseLoader from "react-spinners/PulseLoader";
import {GlobalInfo} from "../../App"

function Login(props) {
  const { loginenter } = useContext(GlobalInfo)

  const [email, setEmail]= useState("");
  const [password, setPassword]= useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigat=useNavigate()

  const Login =async(event)=>{
    event.preventDefault();

    if(!email){
       alert('Please enter your email.');
    }
    else if (!password) {
       alert('Please enter your password.');
    }
    else{
       setIsLoading(true);
  
       console.warn(email,password)

       let  result =await fetch(Connection + "login",{
         method:'post',
         body: JSON.stringify({email,password}),
         headers:{
             "Content-Type":'application/json'
         }
       })
       result=await result.json()
       console.log("result123",result)
 
       if(result == ""){
         setIsLoading(false);
         alert('Something went wrong')
       } 
       else{
         setIsLoading(false);
         localStorage.setItem("user",JSON.stringify(result))
         loginenter()
        //  window.location.href = '/'
       }
      
      
    }
  }


  return (
    <div class="main_Login_div">
      <div className='both_handle'>
        <img src={Mas_ClinicaLogo_2}></img>

        <div class="box">
          <h3>Welcome Back</h3>
          <h5>Enter your credentials to access your account.</h5>

          <form action="">
            <div class="input_box">
              <input type="text" name="Email" id="name" autocomplete="off" placeholder="Email" required onChange={(e)=>setEmail(e.target.value)}/>
              {/* <label for="Username">Email</label> */}
            </div>

            <div class="input_box">
              <input type="password" name="password" id="name" placeholder="Password" autocomplete="off" required onChange={(e)=>setPassword(e.target.value)}/>
              {/* <label for="Password">Password</label> */}
            </div>

            {isLoading ?
              <button id='delete_loader'>
                <PulseLoader
                   color={"white"}
                   loading={isLoading}
                   //  size={5}
                   aria-label="Loading Spinner"
                   data-testid="loader"
                />
              </button>
              :
              <Link className="link" to={"/"} onClick={Login} value="Login" >Log In</Link>
            }

          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;