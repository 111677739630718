import React from 'react'
import './Pending_patient.css';

const Paginations = ({totalPosts, postsPerPage, setCurrentPage, currentPage}) => {
    let pages = [];

    for(let i = 1; i<= Math.ceil(totalPosts/postsPerPage); i++) {
        pages.push(i)
    }
  return (
       <div className='pagination'>
          {
            pages.map((pages, index) => {
               return <li key={index} className={pages == currentPage ? 'pagination_view active' : 'pagination_view'}><a onClick={() => setCurrentPage(pages)} href='#'>{pages}</a></li>
            })
          }
       </div>
  )
}

export default Paginations