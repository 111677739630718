import React, {useState, useEffect } from 'react'
import './Pending_patient.css';
import { Modal,ModalHeader,ModalBody,Row } from 'reactstrap';
import { Link, parsePath,useParams } from "react-router-dom"
import Pagination from './Paginations';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";

export default function Pending_patient() {

  const [modalaproved, setModalaproved] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [products, setProducts]= useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);

  const lastPostIndex = currentPage * postsPerPage
  const firstPostIndex = lastPostIndex - postsPerPage
  const currentPosts = products.slice(firstPostIndex, lastPostIndex)
  
  const [user_id, setUser_id] = React.useState("");

  const [patLoading, setPatLoading] = useState(false);

  const params = useParams();

    useEffect(()=>{
      getProducts()
    },[])
    const getProducts = async()=>{
        let  result =await fetch(Connection + "get_pending_user",{
           
        })
        result= await result.json()
         setProducts(result)
         console.warn(result)
    }


    const open_approved_modal = (id) => {
       setModalaproved(true)
       setUser_id(id)
    }

    const approved_patient =async(event)=>{
       event.preventDefault();

       setPatLoading(true);
       
       const statuss = "approved"

       let result = await fetch(Connection + `approved_patient/${user_id}`,{
         method:'post',
         body: JSON.stringify({statuss}),
         headers:{
             "Content-Type":'application/json'
         }
       })
       result=await result.json()
       console.log("result123",result)

       if(result){
          setPatLoading(false);
          setModalaproved(false)
          getProducts()
       }
    }


    const Search_pending_patient =async(event)=>{
       event.preventDefault();

       const name = event.target.value
       console.log("search",name)
   
       let  result =await fetch(Connection + "search_pending_patient",{
           method:'post',
           body: JSON.stringify({name}), 
           headers:{
               "Content-Type":'application/json'
           }
       })
       result=await result.json()
       console.log("result123",result)
       setProducts(result)
    }



    const open_delete_modal = (id) => {
       setModalDelete(true)
       setUser_id(id)
    }

    const Delete_patient =async(event)=>{
       event.preventDefault();

       setPatLoading(true);

       let  result =await fetch(Connection + `delete_patient/${user_id}`)
       result=await result.json()
       console.log("result123",result)

       if(result){
          setPatLoading(false);
          setModalDelete(false)
          getProducts()
       }
    }


    return (
        <div className='table_view'>
           
            <div className='responsive_card'>
               <div className='head_card'>
                   <h2>PENDING FREE HISD</h2>
                   <div className='search_patient'>
                      <h6>Search</h6>
                      <input type="search" className="search" id="Search" placeholder="Search" onChange={Search_pending_patient}/>
                   </div>
               </div>
              <hr></hr>
 
              <div className='table_responsive'>
                <table className='table_tag'>
                           <tr>
                               <th>ID</th>
                               <th>Image</th>
                               <th>Name</th>
                               <th>Email</th>
                               <th>Phone</th>
                               <th>Work Id</th>
                               <th>Action</th>
                           </tr>


                      {currentPosts.map((row) => (
                           <tr>
                               <td>{row.id}</td>
                               <td>
                                 {row.profile == null ?
                                      <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt=""/>
                                 :
                                      <img src={Image_connection + row.profile} title="Profile Image" alt=""/>
                                 }
                               </td>
                               <td>{row.name}</td>
                               <td>{row.email}</td>
                               <td>{row.mobile_number}</td>
                               <td>{row.work_id}</td>
                               <td>
                                  <span className='aution_btn'>
                                       <button onClick={() => open_approved_modal(row.id)}>Approved</button>
                                       <button onClick={() => open_delete_modal(row.id)}>Remove</button>
                                  </span>
                               </td>
                           </tr>
                      ))}
                </table>
             </div>
            </div>

            <Pagination totalPosts={products.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} currentPage={currentPage}/>

            <Modal size='lg' isOpen={modalaproved} toggle={() => setModalaproved(!modalaproved)}>
               <ModalHeader toggle={() => setModalaproved(!modalaproved)}>
                  APPROVED PATIENT
               </ModalHeader>

               <ModalBody>
                  <div className='delete_modal'>
                      <h4>Are you sure you want to approved this patient?</h4>
                      {patLoading ?
                        <button id='delete_loader'>
                          <PulseLoader
                               color={"white"}
                               loading={patLoading}
                              //  size={5}
                               aria-label="Loading Spinner"
                               data-testid="loader"
                          />
                        </button>
                      :
                        <button onClick={approved_patient}>Approved</button>
                      }
                  </div>
               </ModalBody>
            </Modal>

            <Modal size='lg' isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)}>
               <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
                   DELETE PATIENT
               </ModalHeader>

               <ModalBody>
                  <div className='delete_modal'>
                      <h4>Are you sure you want to delete this patient?</h4>
                      {patLoading ?
                        <button id='delete_loader'>
                          <PulseLoader
                               color={"white"}
                               loading={patLoading}
                               aria-label="Loading Spinner"
                               data-testid="loader"
                          />
                        </button>
                      :
                        <button onClick={Delete_patient}>Delete</button>
                      }
                  </div>
               </ModalBody>
            </Modal>
         </div>
    );
}