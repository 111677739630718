import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom"
import './Add_doctor.css';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import moment from 'moment';
export default function Add_doctor() {

   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [mobile_number, setMobile_number] = useState("");
   const [address, setAddress] = useState("");
   const [category, setCategory] = useState("Choose Category");
   const [consult_now, setConsult_now] = useState("Choose Consult");
   const [fee, setFee] = useState("");
   const [city, setCity] = useState("State");
   const [gender, setGender] = useState("Gender");
   const [degree, setDegree] = useState("");
   const [experience, setExperience] = useState("");
   const [c_name, setC_name] = useState("");
   const [free_hisd, setFree_hisd] = useState("Choose Free Hisd");

   const [modalsuccess, setModalsuccess] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const [error, setError] = useState(false);


   const navigate = useNavigate();



   const getAddress = (event) => {
      event.preventDefault();
      if (!name || !email || !password || !mobile_number || !address) {
         setError(true)
         alert('Information is not complete');
         return false
      }
      else if (gender == 'Gender') {
         alert('Please select gender');
         return false
      }
      else if (city == 'State') {
         alert('Please select State');
         return false
      }
      else if (category == 'Choose Category') {
         alert('Please select Category');
         return false
      }
      else if (!degree || !experience) {
         alert('Information is not complete');
         return false
      }
      else if (consult_now == 'Choose Consult') {
         alert('Please select Consult');
         return false
      }
      else if (free_hisd == 'Choose Free Hisd') {
         alert('Please select Free Hisd');
         return false
      }
      else if (!fee) {
         alert('Information is not complete');
         return false
      }
      else if (c_name.length < 70) {
         alert('Write about 70 words of your Clinic');
         return false
      }

      else {
         setIsLoading(true);

         let api = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyAr6YRTjfl2eKXIJZd97_uw9KpStXFRiCE`;
         console.log("pass => ", api);

         fetch(api, {
            method: "POST",
         })
            .then((response) => response.json())
            .then((response) => {
               console.log("pass => ", response);
               let result = response["results"];
               if (result == "") {
                  setIsLoading(false);
                  alert("Invalid Address.")
               }
               else {
                  //getting lat lng from user address
                  let all = result[0].geometry;
                  let location = all.location;
                  let lat = location["lat"];
                  let lng = location["lng"];

                  console.log("lat => ", lat);
                  console.log("lng => ", lng);
                  // setLat(lat);
                  // setLng(lng);

                  Add_Doctor(lat, lng)
               }
            })
            .catch((error) => {
               setIsLoading(false);
               alert("Something went wrong.")
               console.error(error);
            });
      }
   };


   const Add_Doctor = async (lat, lng) => {
      var length = 8,
         charset = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
         r_code = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
         r_code += charset.charAt(Math.floor(Math.random() * n));
      }

      const role = "doctor"
      const status = "approved"
      const user_date = moment(new Date()).format("YYYY-MM-DD hh:mm A");

      console.log(name, email, password, mobile_number, address, city, category, consult_now, fee, c_name)

      let result = await fetch(Connection + "add_doctor", {
         method: 'post',
         body: JSON.stringify({ name, email, password, mobile_number, address, lat, lng, user_date, gender, city, category, degree, experience, consult_now, fee, r_code, role, status, c_name, free_hisd }),
         headers: {
            "Content-Type": 'application/json'
         }
      })

      result = await result.json()
      console.log("result123", result)
      console.log("result.auth", result.auth)

      if (result) {
         setIsLoading(false);
         setModalsuccess(true)
      }
   }


   const Succeess = async (event) => {
      setModalsuccess(false)
      navigate('/Doctor_list')
   }


   return (
      <div className='firstinpu'>

         <form className='Form-all-view'>
            <h2>ADD DOCTOR</h2>
            <hr></hr>

            {/* <div className='profileImage'>
                   <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRt_NZykul07nU3cliFuRZQr4_q-gOdkRTmRA&usqp=CAU" alt="" className="fab fa-firstdraft" />
                   <a href='' className='prfilebtn'>Add Profile</a>
               </div> */}

            <div className="first-name">
               <div className='width-both-name-email'>
                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Name</label>
                     </div>
                     <input type="text" className="" id="name" placeholder="Your Name" onChange={(e) => setName(e.target.value)} />
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Email</label>
                     </div>
                     <input type="email" className="" id="email" placeholder="Your Email" onChange={(e) => setEmail(e.target.value)} />
                  </div>
               </div>
            </div>


            <div className="first-name">
               <div className='width-both-name-email'>
                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Password</label>
                     </div>
                     <input type="text" className="" id="name" placeholder="Your Password" onChange={(e) => setPassword(e.target.value)} />
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Mobile Number</label>
                     </div>
                     <input type="text" className="" id="email" placeholder="Mobile Number" onChange={(e) => setMobile_number(e.target.value)} />
                  </div>
               </div>
            </div>


            <div className="second-subject">
               <div className='width-subject'>
                  <div className='subject-name'>
                     <label>Address</label>
                  </div>
                  <input type="text" className="subject" id="subject" placeholder="Address" onChange={(e) => setAddress(e.target.value)} />
               </div>
            </div>


            <div className="first-name">
               <div className='width-both-name-email'>
                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Gender</label>
                     </div>
                     <select id="email" pla value={gender} onChange={(e) => setGender(e.target.value)}>
                        <option>{gender}</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                     </select>
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>State</label>
                     </div>
                     <select id="subject" value={city} onChange={(e) => setCity(e.target.value)}>
                        <option>{city}</option>
                        <option value="Texas">Texas</option>
                        <option value="California">California</option>
                        <option value="Arizona">Arizona</option>
                        <option value="Louisiana">Louisiana</option>
                        <option value="New Mexico">New Mexico</option>
                        <option value="Florida">Florida</option>
                        <option value="Alabama">Alabama</option>
                        <option value="Alaska">Alaska</option>
                        <option value="Arkansas">Arkansas</option>
                        <option value="Colorado">Colorado</option>
                        <option value="Connecticut">Connecticut</option>
                        <option value="Delaware">Delaware</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Idaho">Idaho</option>
                        <option value="Illinois">Illinois</option>
                        <option value="Indiana">Indiana</option>
                        <option value="Iowa">Iowa</option>
                        <option value="Kansas">Kansas</option>
                        <option value="Kentucky">Kentucky</option>
                        <option value="Maine">Maine</option>
                        <option value="Maryland">Maryland</option>
                        <option value="Massachusetts">Massachusetts</option>
                        <option value="Michigan">Michigan</option>
                        <option value="Minnesota">Minnesota</option>
                        <option value="Mississippi">Mississippi</option>
                        <option value="Missouri">Missouri</option>
                        <option value="Montana">Montana</option>
                        <option value="Nebraska">Nebraska</option>
                        <option value="Nevada">Nevada</option>
                        <option value="New Hampshire">New Hampshire</option>
                        <option value="New Jersey">New Jersey</option>
                        <option value="New York">New York</option>
                        <option value="North Carolina">North Carolina</option>
                        <option value="North Dakota">North Dakota</option>
                        <option value="Ohio">Ohio</option>
                        <option value="Oklahoma">Oklahoma</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Pennsylvania">Pennsylvania</option>
                        <option value="Rhode Island">Rhode Island</option>
                        <option value="South Carolina">South Carolina</option>
                        <option value="South Dakota">South Dakota</option>
                        <option value="Tennessee">Tennessee</option>
                        <option value="Utah">Utah</option>
                        <option value="Vermont">Vermont</option>
                        <option value="Virginia">Virginia</option>
                        <option value="Washington">Washington</option>
                        <option value="West Virginia">West Virginia</option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="Wyoming">Wyoming</option>
                     </select>
                  </div>
               </div>
            </div>

            <div className="first-name">
               <div className='width-both-name-email'>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Choose Category</label>
                     </div>
                     <select id="name" onChange={(e) => setCategory(e.target.value)}>
                        <option>{category}</option>
                        <option value="General Clinic">General Clinic</option>
                        <option value="Dentists">Dentists</option>
                        <option value="Cardialogy">Cardialogy</option>
                        <option value="Pediatric">Pediatric</option>
                        <option value="Dermatology">Dermatology</option>
                        <option value="Orthopedician">Orthopedician</option>
                        <option value="Neurologists">Neurologists</option>
                        <option value="Mental Health">Mental Health</option>
                        <option value="Therapist">Therapist</option>
                     </select>
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Fee</label>
                     </div>
                     <input type="number" className="" id="email" placeholder="Your Fee" onChange={(e) => setFee(e.target.value)} />
                  </div>

               </div>
            </div>


            <div className="first-name">
               <div className='width-both-name-email'>
                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Degree</label>
                     </div>
                     <input type="text" className="" id="email" placeholder="Degree" onChange={(e) => setDegree(e.target.value)} />
                  </div>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Experience Years (Only Digits)</label>
                     </div>
                     <input type="number" className="" id="email" placeholder="Experience" onChange={(e) => setExperience(e.target.value)} />
                  </div>
               </div>
            </div>


            <div className="first-name">
               <div className='width-both-name-email'>

                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Consult Now</label>
                     </div>
                     <select id="name" onChange={(e) => setConsult_now(e.target.value)}>
                        <option>{consult_now}</option>
                        <option value="On">On</option>
                        <option value="Off">Off</option>
                     </select>
                  </div>


                  <div className='width-name'>
                     <div className='subject-name'>
                        <label>Free hisd</label>
                     </div>
                     <select id="name" onChange={(e) => setFree_hisd(e.target.value)}>
                        <option>{free_hisd}</option>
                        <option value="true">true</option>
                        <option value="false">false</option>
                     </select>
                  </div>

               </div>
            </div>


            <div className="second-subject">
               <div className='width-subject'>
                  <div className='subject-name'>
                     <label>About Clinic (70 words required)</label>
                  </div>
                  <textarea className="message" placeholder="Write about your Clinic" onChange={(e) => setC_name(e.target.value)} id="message" ></textarea>
               </div>
            </div>

            <div className="buttun-center">
               <button className="submit" id='reset' type="reset">Reset</button>
               {isLoading ?
                  <button class="submit" id='loader' type="reset">
                     <PulseLoader
                        color={"white"}
                        loading={isLoading}
                        //  size={5}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                     />
                  </button>
                  :
                  <button onClick={getAddress} className="submit" id='submite'>Submit</button>
               }
            </div>
         </form>

         <Modal size='lg' isOpen={modalsuccess} toggle={() => setModalsuccess(!modalsuccess)}>
            <ModalHeader toggle={() => setModalsuccess(!modalsuccess)}>
               Successfully
            </ModalHeader>

            <ModalBody>
               <div className='succeess_modal'>
                  <h4>You have successfully Add Doctor</h4>
                  <button onClick={Succeess}>Done</button>
               </div>
            </ModalBody>
         </Modal>
      </div>
   );
}