import React, {useState, useEffect, useMemo, useContext } from 'react'
import {Link} from "react-router-dom"
import './Home.css';
import Connection from "../../connection";
import Image_connection from "../../Image_connection";
import {GlobalInfo} from "../../App"

export default function Home() {
   const { appointment, doctor, patient, app_count, user_count, doctor_count, pen_doctor_count } = useContext(GlobalInfo)

   // const [appointment, setAppointment]= useState([]);
   // const [doctor, setDoctor]= useState([]);
   // const [patient, setPatient]= useState([]);
   
   // const [app_count, setApp_count] = React.useState(0);
   // const [user_count, setUser_count] = React.useState(0);
   // const [doctor_count, setDoctor_count] = React.useState(0);
   // const [pen_doctor_count, setPen_doctor_count] = React.useState(0);

   useEffect(()=>{
      // get_all_counting()
      // get_appointment()
      // get_doctor()
      // get_user()
   },[])
   
   //  const get_all_counting= async()=>{
   //    let  result =await fetch(Connection + "get_count",{

   //    })
   //    result= await result.json()
   //     const app_count = result[0].app_count
   //     const user_count = result[0].user_count
   //     const doctor_count = result[0].doctor_count
   //     const pen_doctor_count = result[0].pen_doctor_count

   //     setApp_count(app_count)
   //     setUser_count(user_count)
   //     setDoctor_count(doctor_count)
   //     setPen_doctor_count(pen_doctor_count)

   //     console.warn(result)

   //  }

   //  const get_appointment= async()=>{
   //      let  result =await fetch(Connection + "das_get_appointment",{

   //      })
   //      result= await result.json()
   //       setAppointment(result)
   //       console.warn(result)

   //  }
  
   //  const get_doctor= async()=>{
   //      let  result =await fetch(Connection + "das_get_doctor",{

   //      })
   //      result= await result.json()
   //       setDoctor(result)
         
   //       console.warn(result)
   //  }

   //  const get_user= async()=>{
   //    let  result =await fetch(Connection + "das_get_user",{
         
   //    })
   //    result= await result.json()
   //     setPatient(result)
   //     console.warn(result)
   //  }

 
   return (
        <div className='dashboard'>
         
            <div className='dashboard_head'>
               <div className='first_box'>
                  <div className='box_left_side'>
                     <h4>DOCTORS</h4>
                     <h3>{doctor_count}</h3>
                  </div>

                  <div className='box_right_side'>
                     <i class="ms-icon-mr fas fa-stethoscope"></i>
                  </div>
               </div>
           
               <div className='first_box'>
                  <div className='box_left_side'>
                     <h4>PENDING</h4>
                     <h3>{pen_doctor_count}</h3>
                  </div>

                  <div className='box_right_side'>
                     <i class="fas fa-user-md" aria-hidden="true"></i>
                  </div>
               </div>

               <div className='first_box'>
                  <div className='box_left_side'>
                     <h4>PATIENT</h4>
                     <h3>{user_count}</h3>
                  </div>

                  <div className='box_right_side'>
                     <i class="ms-icon-mr fas fa-wheelchair"></i>
                  </div>
               </div>

               <div className='first_box'>
                  <div className='box_left_side'>
                     <h4>APPOINTMENT</h4>
                     <h3>{app_count}</h3>
                  </div>

                  <div className='box_right_side'>
                     <i class="ms-icon-mr fas fa-briefcase-medical"></i>
                  </div>
               </div>
            </div>


            <div className='dashboard_body'>
            <div className='dashbord_appoint'>
                      <div className='head_in_card'>
                           <h4>Upcoming Appointent</h4>
                           <Link to="/Appointment">
                               <button>View All</button>
                           </Link>
                      </div>
                      <hr></hr>
 
                      <div className='dashbord_table'>
                         <table className='dashbord_table_tag'>

                           <tr>
                               <th>Doctor</th>
                               <th>Patient</th>
                               <th>Category</th>
                               <th>Date</th>
                               <th>Time</th>
                               <th>Action</th>
                           </tr>

                           {appointment.map((row) => (
                              <tr>
                                 <td>
                                     <div className='image_name'>
                                        {row.doctor_profile == null ?
                                            <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt=""/>
                                         :
                                            <img src={Image_connection + row.doctor_profile} title="Profile Image" alt=""/>
                                        }

                                        <h5>{row.doctor_name}</h5>
                                     </div>
                                 </td>

                                 <td>
                                     <div className='image_name'>
                                        {row.user_profile == null ?
                                           <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt=""/>
                                        :
                                           <img src={Image_connection + row.user_profile} title="Profile Image" alt=""/>
                                        }

                                        <h5>{row.user_name}</h5>
                                     </div>
                                 </td>
                                 
                                 <td>{row.category}</td>
                                 <td>{row.date}</td>
                                 <td>{row.time}</td>
                                 <td>
                                     <div className='dash_status'>
                                        {row.status}
                                     </div>
                                 </td>
                              </tr>
                           ))}
                         </table>
                      </div>
            </div>
            </div>



            <div className='dashboard_body'>
                <div className='first_column'>
               
                   <div className='doctor_List'>
                      <div className='doctor_top'>
                           <h5>  Doctor Lists</h5>
                           <Link to="/Doctor_list">
                               <button>View All</button>
                           </Link>
                      </div>

                      <div className='doctor_dash'>
                         <table className='doc_table'>

                           <tr>
                               <th>#</th>
                               <th>Name</th>
                               <th>Number</th>
                               <th>Category</th>
                               {/* <th>Action</th> */}
                           </tr>

                           {doctor.map((row) => (
                              <tr>
                                 <td>
                                    {row.profile == null ?
                                      <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt=""/>
                                    :
                                      <img src={Image_connection + row.profile} title="Profile Image" alt=""/>
                                    }
                                 </td>
                                 <td>{row.name}</td>
                                 <td>{row.mobile_number}</td>
                                 <td>
                                     <div className='dash_status'>
                                        {row.category}
                                     </div>
                                 </td> 
                                 {/* <td>
                                     <div className='two_icon'>
                                        <i class="bi bi-pencil-square"></i>
                                        <i class="bi bi-trash"></i>
                                     </div>
                                 </td> */}
                              </tr>
                           ))}
                         </table>
                      </div>
                   </div>   
             
                </div>

                <div className='second_column'>
                  
                  <div className='Patient_List'>
                      <div className='head_card'>
                           <h5>Patient Lists</h5>
                           <Link to="/Patient_list">
                               <button>View All</button>
                           </Link>
                      </div>

                      <div className='center_list'>
                         <table className='pati_table'>

                           <tr>
                               <th>#</th>
                               <th>Name</th>
                               <th>Number</th>
                           </tr>

                           {patient.map((row) => (
                              <tr>
                                 <td>
                                    {row.profile == null ?
                                      <img src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTiQc9dZn33Wnk-j0sXZ19f8NiMZpJys7nTlA&usqp=CAU"} title="Profile Image" alt=""/>
                                    :
                                      <img src={Image_connection + row.profile} title="Profile Image" alt=""/>
                                    }
                                 </td>
                                 <td>{row.name}</td>
                                 <td>
                                     <div className='patient_status'>
                                        {row.mobile_number}
                                     </div>
                                 </td> 
                              </tr>
                           ))}
                         </table>
                      </div>

                  </div>
              
                </div>
            </div>
         
        </div>
   );
}